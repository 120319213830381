import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { GuestFeedback } from '../GuestFeedback/GuestFeedback'
import { FeatureFlagsProvider } from '../../context/FeatureFlagsProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider>
        <SnackBarProvider>
          <BrowserRouter basename='/'>
            <Routes>
              <Route path='*' element={<GuestFeedback />} />
            </Routes>
          </BrowserRouter>
        </SnackBarProvider>
      </FeatureFlagsProvider>
    </QueryClientProvider>
  )
}
