import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { TextArea } from '../Inputs/TextArea'
import { t } from '@local/translations'

export const TextFeedback = () => {
  const { state, updateState } = useGuestFeedbackContext()
  const { feedbackFreeFormText } = state

  const onChangeTextArea = (newValue: string) => {
    updateState({ feedbackFreeFormText: newValue })
  }

  return (
    <div className='pt-6'>
      <TextArea
        testId='text-feedback'
        className='h-28'
        placeholder={t(
          'tell-us-a-bit-more-your-insights-help-us-and-our-future-guests-max-250-characters'
        )}
        maxChars={250}
        value={feedbackFreeFormText}
        onChange={onChangeTextArea}
      />
    </div>
  )
}
