import { CardSelector } from '@toasttab/buffet-pui-card-selector'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { onEnter } from '../../domain/events'
import { t } from '@local/translations'

export const Reasons = () => {
  const { state, updateState, reasons } = useGuestFeedbackContext()
  const { feedbackReasons, feedbackRating } = state
  const isPositive =
    feedbackRating === 'POSITIVE' || feedbackRating === 'SOMEWHAT_POSITIVE'

  const onToggleReason = (reason: string) => {
    const newFeedbackReasons = feedbackReasons.includes(reason)
      ? feedbackReasons.filter((r) => r !== reason)
      : feedbackReasons.concat(reason)
    updateState({ feedbackReasons: newFeedbackReasons })
  }

  return (
    <>
      <p className='type-headline-4 text-default font-medium text-center pt-8'>
        {isPositive ? t('glad-to-hear') : t('sorry-to-hear-that')}
      </p>
      <p className='type-default text-default text-center'>
        {isPositive
          ? t('what-made-it-great')
          : t('what-could-have-been-better')}
      </p>
      <ul className='flex flex-row flex-wrap gap-2 justify-center my-3'>
        {reasons.map((reason) => (
          <li key={reason}>
            <CardSelector
              className='text-center'
              multiple={true}
              hideMultiSelectCheckboxes={true}
              checked={feedbackReasons.includes(reason)}
              onChange={() => onToggleReason(reason)}
              onKeyUp={onEnter(() => onToggleReason(reason))}
            >
              <p>{reason}</p>
            </CardSelector>
          </li>
        ))}
      </ul>
    </>
  )
}
