import React from 'react'
import { t } from '@local/translations'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  DEFAULT_GUEST_FEEDBACK_CONTEXT,
  DEFAULT_GUEST_FEEDBACK_STATE,
  GuestFeedbackContextType,
  GuestFeedbackState,
  createGuestFeedbackState
} from '../domain/guestFeedbackDetailsContext'
import { useQueryGuestFeedback } from '../hooks/useQueryGuestFeedback'
import { useMutateGuestFeedback } from '../hooks/useMutateGuestFeedback'
import { trackEvent } from '../domain/tracking'
import { useGuestFeedbackUrlData } from '../hooks/useGuestFeedbackUrlData'

const GuestFeedbackContext = React.createContext<GuestFeedbackContextType>(
  DEFAULT_GUEST_FEEDBACK_CONTEXT
)

export const useGuestFeedbackContext = () => {
  return React.useContext(GuestFeedbackContext)
}

interface GuestFeedbackProviderProps {
  mockContext?: GuestFeedbackContextType
  children?: React.ReactNode
}

export const GuestFeedbackProvider = ({
  mockContext,
  children
}: GuestFeedbackProviderProps) => {
  const urlData = useGuestFeedbackUrlData()
  const { isBadUrlData } = urlData
  const { showErrorSnackBar } = useSnackBar()
  const [state, setState] = React.useState<GuestFeedbackState>(
    DEFAULT_GUEST_FEEDBACK_STATE
  )

  React.useEffect(() => {
    if (isBadUrlData) {
      trackEvent({
        name: 'Bad Url Data',
        tags: { urlData: JSON.stringify(urlData) }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const queryGuestFeedbackOutput = useQueryGuestFeedback({
    enabled: !mockContext,
    onLoadSuccess: (response) => {
      setState(createGuestFeedbackState(response))
      trackEvent({
        name: 'Get Success',
        tags: {
          feedbackState: response?.feedbackState,
          feedbackRating: response?.feedback?.feedbackRating,
          feedbackRawRating: response?.feedback?.feedbackRawRating
        }
      })
    },
    onLoadError: () => {
      trackEvent({ name: 'Get Error' })
    }
  })

  const {
    mutate,
    isLoading: saving,
    isSuccess: isComplete
  } = useMutateGuestFeedback({
    feedbackGuid: queryGuestFeedbackOutput?.guid,
    onSuccess: () => {
      trackEvent({
        name: 'Save Success',
        tags: {
          feedbackRating: state?.feedbackRating,
          feedbackRawRating: state?.feedbackRawRating
        }
      })
    },
    onError: () => {
      showErrorSnackBar(t('there-was-an-error-saving-please-try-again-later'))
      trackEvent({ name: 'Save Error' })
    }
  })

  const updateState = (partialState: Partial<GuestFeedbackState>) => {
    const newState: GuestFeedbackState = {
      ...state,
      ...partialState,
      contactInformation: {
        ...state?.contactInformation,
        ...partialState?.contactInformation
      }
    }
    setState(newState)
  }

  const saveFeedback = () => {
    mutate(state)
  }

  const value = mockContext || {
    ...queryGuestFeedbackOutput,
    state,
    updateState,
    saveFeedback,
    saving,
    isComplete
  }

  return (
    <GuestFeedbackContext.Provider value={value}>
      {children}
    </GuestFeedbackContext.Provider>
  )
}
