import {
  Textarea as TA,
  TextareaProps as TAProps
} from '@toasttab/buffet-pui-text-area'
import { TextInputEvent } from '../../domain/inputs'

export interface TextAreaProps extends Omit<TAProps, 'onChange'> {
  value: string
  onChange: (newValue: string) => void
  maxChars: number
}

export const TextArea = ({
  value,
  onChange,
  maxChars,
  ...props
}: TextAreaProps) => {
  // Note: Only show max character text for the last 50 characters
  const showMaxChars = !!value && value.length > maxChars - 50

  const onChangeInput = (e: TextInputEvent) => {
    const newValue = e?.target?.value || ''
    const slicedNewValue = newValue.slice(0, maxChars)
    onChange(slicedNewValue)
  }

  return (
    <TA
      {...props}
      value={value}
      onChange={onChangeInput}
      maxChars={showMaxChars ? maxChars : undefined}
    />
  )
}
