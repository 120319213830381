export enum FeatureFlags {
  MENU_ITEM = 'grmGuestFeedbackMenuItemCollection',
  STARS_SURVEY = 'grmFeedbackStarRating',
  EMOJIS_SURVEY = 'grmFeedbackEmojiRating'
}

export interface FeatureFlagValues {
  [FeatureFlags.MENU_ITEM]: boolean
  [FeatureFlags.STARS_SURVEY]: boolean
  [FeatureFlags.EMOJIS_SURVEY]: boolean
}

export interface UseFeatureFlags {
  isLoading: boolean
  isError: boolean
  flags: FeatureFlagValues
}
