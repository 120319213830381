import * as React from 'react'

import { Prefix } from './Prefix'
import { Suffix } from './Suffix'
import { Variant } from './variant'

export const getInputPaddingLeft = ({
  prefix,
  prefixVariant
}: {
  prefix?: React.ReactNode
  prefixVariant?: Variant
}) => {
  if (!prefix) {
    return 'calc(0.75rem - 2px)'
  }
  switch (prefixVariant) {
    case Prefix.Variant.textOnly:
      return '0px'
    case Prefix.Variant.icon:
    case Prefix.Variant.iconButton:
      return 'calc(2.75rem - 2px)'
    case Prefix.Variant.bgGray:
    default:
      return '0.75rem'
  }
}

export const getInputPaddingRight = ({
  suffix,
  suffixVariant
}: {
  suffix?: React.ReactNode
  suffixVariant?: Variant
}) => {
  if (!suffix) {
    return 'calc(0.75rem - 2px)'
  }
  switch (suffixVariant) {
    case Suffix.Variant.textOnly:
      return '0px'
    case Suffix.Variant.icon:
      return 'calc(2.75rem - 2px)'
    case Suffix.Variant.iconButton:
      return '0.5rem'
    case Suffix.Variant.bgGray:
    default:
      return '0.75rem'
  }
}
