import React from 'react'
import { FeatureFlagValues } from '../app/configs/featureFlags'

export interface MockContext {
  mockUseFeatureFlags?: FeatureFlagValues
}

export interface MockProviderProps extends MockContext {
  children: React.ReactNode
}

const MockContext = React.createContext<MockContext>({})

export const MockProvider = ({ children, ...props }: MockProviderProps) => {
  return <MockContext.Provider value={props}>{children}</MockContext.Provider>
}

export const useMockContext = () => {
  return React.useContext(MockContext)
}
