import { t } from '@local/translations'
import { StarFilledIcon } from '@toasttab/buffet-pui-icons'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { onEnter } from '../../domain/events'
import ThumbsUp from '../../../assets/thumbs-up.svg'
import ThumbsDown from '../../../assets/thumbs-down.svg'
import { FeatureFlags } from '../../configs/featureFlags'
import { useFeatureFlags } from '../../context/FeatureFlagsProvider'
import {
  useGuestFeedbackRating,
  ratingConfig
} from '../../hooks/useGuestFeedbackRating'

export const Rating = () => {
  const { state, updateState } = useGuestFeedbackContext()
  const { clearRating } = useGuestFeedbackRating()
  const featureFlags = useFeatureFlags()
  const { feedbackRating } = state
  const starsFF = featureFlags[FeatureFlags.STARS_SURVEY]
  const emojisFF = featureFlags[FeatureFlags.EMOJIS_SURVEY]

  const onToggleRating = () => {
    if (starsFF || emojisFF) {
      // redirect user to no ratings page
      updateState({ feedbackRating: undefined })
      clearRating()
    } else {
      const newFeedbackRating =
        feedbackRating === 'POSITIVE' ? 'NEGATIVE' : 'POSITIVE'
      updateState({ feedbackRating: newFeedbackRating })
    }
  }

  const getStars = (count: number) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <StarFilledIcon
          key={getFeedbackLabel(i + 1)}
          data-testid={`star-icon-${getFeedbackLabel(i + 1)}`}
          accessibility='decorative'
          size='lg'
        />
      ))}
    </>
  )

  const getFeedbackLabel = (count: number) => {
    switch (count) {
      case 1:
        return 'negative-feedback'
      case 2:
        return 'somewhat-negative-feedback'
      case 3:
        return 'passive-feedback'
      case 4:
        return 'somewhat-positive-feedback'
      default:
        return 'positive-feedback'
    }
  }

  const getEmojiImage = (label: string, IconComponent: React.ElementType) => (
    <IconComponent
      data-testid={`face-icon-${label}`}
      accessibility='decorative'
      size='lg'
    />
  )

  const ratingImage = () => {
    if (starsFF || emojisFF) {
      const ratingOption = ratingConfig.find(
        ({ type }) => type === feedbackRating
      )
      if (!ratingOption) return null

      return starsFF
        ? getStars(ratingOption.index)
        : getEmojiImage(getFeedbackLabel(ratingOption.index), ratingOption.icon)
    }

    return feedbackRating === 'POSITIVE' ? (
      <ThumbsUp aria-label={t('rating', { context: feedbackRating })} />
    ) : (
      <ThumbsDown aria-label={t('rating', { context: feedbackRating })} />
    )
  }

  return (
    <div className='pt-12 px-4 flex flex-col items-center'>
      <div className='text-primary-75'>{ratingImage()}</div>
      <p className='type-default text-default text-center'>
        {t('experience-rating', { context: feedbackRating })}
      </p>
      <div
        role='button'
        tabIndex={0}
        onClick={onToggleRating}
        onKeyDown={onEnter<HTMLInputElement>(onToggleRating)}
        className='type-subhead text-gray-75 hover:text-gray-100 underline px-1 outline-none focus:shadow-focus rounded select-none'
      >
        {t('i-changed-my-mind')}
      </div>
    </div>
  )
}
