import { Rating } from './Rating'
import { Reasons } from './Reasons'
import { OrderItems } from './OrderItems'
import { TextFeedback } from './TextFeedback'
import { LearnMore } from './LearnMore'
import { SubmitButton } from './SubmitButton'
import { Disclaimers } from './Disclaimers'
import { useGuestFeedbackContext } from '../../context/GuestFeedbackContext'
import { isIntl } from '../../domain/international'
import { FeatureFlags } from '../../configs/featureFlags'
import { useFeatureFlags } from '../../context/FeatureFlagsProvider'

export const GuestFeedbackForm = () => {
  const { intlProps } = useGuestFeedbackContext()
  const featureFlags = useFeatureFlags()

  return (
    <>
      <Rating />
      <Reasons />
      {featureFlags[FeatureFlags.MENU_ITEM] && <OrderItems />}
      <TextFeedback />
      {isIntl(intlProps) && <LearnMore />}
      <SubmitButton />
      <Disclaimers />
    </>
  )
}
